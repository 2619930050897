<template>
  <AdminLayout active-page="adminRadios">
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-tower-broadcast me-2"></i>Ραδιόφωνα Πελατών</h1>
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'adminDashboard'}"><i class="fas fa-house me-1"></i>Αρχική</router-link>
        </li>
        <li :class="'breadcrumb-item' + (selectedRadio === null ? ' active' : '')">
          <span v-if="selectedRadio === null"><i class="fas fa-tower-broadcast me-1"></i>Ραδιόφωνα Πελατών</span>
          <span v-else><a href="#" @click="unselectRadio"><i class="fas fa-tower-broadcast me-1"></i>Ραδιόφωνα Πελατών</a></span>
        </li>
        <li class="breadcrumb-item active" v-if="selectedRadio !== null"><i
            class="fas fa-satellite-dish me-1"></i>{{ selectedRadio.name }}
        </li>
      </ol>

      <div v-if="selectedRadio === null">
        <RadiosTableComponent @radio-selected="selectRadio" @radio-unselected="unselectRadio"/>
      </div>

      <div v-else>
        <ProgramComponent :radio="selectedRadio"/>

        <BannersComponent :radio="selectedRadio"/>
      </div>

    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import RadiosTableComponent from "@/views/Admin/Radios/AdminRadiosTableComponent.vue";
import BannersComponent from "@/views/Radios/BannersComponent.vue";
import ProgramComponent from "@/views/Radios/ProgramComponent.vue";
import isAdminTokenValid from "@/library/CheckAdminToken";

export default {
  name: "AdminRadiosView" ,
  components: {ProgramComponent, BannersComponent, RadiosTableComponent, AdminLayout},
  beforeRouteEnter(to, from, next) {
    isAdminTokenValid().then(isValid => {
      if (!isValid) {
        next({name: "adminLogin"});
        return;
      }
      next();

    }).catch(() => {
      next({name: "adminLogin"});
    });
  },
  data() {
    return {
      selectedRadio: null,
    }
  },
  methods: {
    selectRadio(radio) {
      this.selectedRadio = radio;
    },
    unselectRadio() {
      this.selectedRadio = null;
    }
  }
}
</script>

<style scoped>

</style>