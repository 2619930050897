function validateString(value, minLength, maxLength) {
    if (value === '' || value === undefined || value === null) {
        return {success: false, message: 'empty'}
    }
    if (value.length < minLength) {
        return {success: false, message: 'min'}
    }
    if (value.length > maxLength) {
        return {success: false, message: 'max'}
    }

    return {success: true, message: ''}
}

function validateNumber(value, minLength, maxLength) {
    if (value === '' || value === undefined || value === null) {
        return {success: false, message: 'empty'}
    }
    if (parseInt(value) < minLength) {
        return {success: false, message: 'min'}
    }
    if (parseInt(value) > maxLength) {
        return {success: false, message: 'max'}
    }
    return {success: true, message: ''}
}

function validateBirthdate(value, min, max) {
    if (value === '' || value === undefined || value === null) {
        return {success: false, message: 'empty'}
    }
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(value)) {
        return {success: false, message: 'pattern'}
    }

    const today = new Date();
    const birthDate = new Date(value);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust if the current month/day is before the birth month/day
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    if (age < min) {
        return {success: false, message: 'min'}
    }

    if (birthDate > today) {
        return {success: false, message: 'future'}
    }

    if (age > max) {
        return {success: false, message: 'max'}
    }

    return true;
}

function validateVat(vat) {
    if (vat === '' || vat === undefined || vat === null) {
        return {success: false, message: 'empty'}
    }

    // Ελέγχουμε αν ο ΑΦΜ αποτελείται από 9 ψηφία
    if (!/^\d{9}$/.test(vat)) {
        return {success: false, message: 'digits'}
    }

    // Μετατρέπουμε το ΑΦΜ σε array αριθμών
    let digits = vat.split('').map(Number);

    // Το τελευταίο ψηφίο είναι το ψηφίο ελέγχου
    let checkDigit = digits.pop();

    // Υπολογίζουμε το βάρος για τα πρώτα 8 ψηφία
    let sum = 0;
    for (let i = 0; i < 8; i++) {
        sum += digits[i] * Math.pow(2, 8 - i); // Βάρη: 256, 128, 64, ..., 2
    }

    // Βρίσκουμε το υπόλοιπο από τη διαίρεση με το 11
    let remainder = sum % 11;

    // Αν το υπόλοιπο είναι 10, το ψηφίο ελέγχου πρέπει να είναι 0
    if (remainder === 10) {
        remainder = 0;
    }

    // Ελέγχουμε αν το ψηφίο ελέγχου ταιριάζει με το υπολογισμένο υπόλοιπο
    let result = checkDigit === remainder;
    if (!result) {
        return {success: false, message: 'invalid'}
    }
    return {success: true, message: ''}
}

function validateEmail(email) {
    if (email === '' || email === undefined || email === null) {
        return {success: false, message: 'empty'}
    }
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    const result = emailRegex.test(email);
    if (!result) {
        return {success: false, message: 'invalid'}
    }
    return {success: true, message: ''}
}

export {validateString, validateNumber, validateBirthdate, validateVat, validateEmail};