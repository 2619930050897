import axios from "axios";
import apiRoutes from "@/router/apiRoutes";

const checkAdminTokenValidity = async () => {
    try {
        const response = await axios.get(apiRoutes.auth.checkAdminToken, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('adminToken')}`
            }
        });
        return response.status === 200;
    } catch (error) {
        localStorage.clear();
        return false;
    }
}

const isAdminTokenValid = async () => {
    return await checkAdminTokenValidity()
}

export default isAdminTokenValid