<template>
  <div class="modal fade" ref="radiosModal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-tower-broadcast me-2"></i>{{ config.title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-12">
              <label for="input-type">Πελάτης</label>
              <vSelect id="product-products"
                          :options="clients"
                          :multiple="false"
                          :placeholder="'Επιλέξτε Πελάτη'"
                          v-model="form.client"
                          @option:selected="filterClients('')"
                          @option:deselected="filterClients('')"
                          @keyup="filterClients($event.target.value)"
              >
                <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                </template>
              </vSelect>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-name">Όνομα</label>
              <input type="text" class="form-control" id="input-name" v-model="form.name">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-email">E-mail</label>
              <input type="email" class="form-control" id="input-email" autocomplete="off" v-model="form.email">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-date-create">Ημ/νία Δημιουργίας</label>
              <input type="date" class="form-control" id="input-date-create" v-model="form.created">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-date-expire">Ημ/νία Λήξης</label>
              <input type="date" class="form-control" id="input-date-expire" v-model="form.expires">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-android-app-key">Android APP KEY</label>
              <input type="text" class="form-control" id="input-android-app-key" v-model="form.android_app_key">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-ios-app-key">iOS APP KEY</label>
              <input type="text" class="form-control" id="input-ios-app-key" v-model="form.ios_app_key">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-android-api-key">Android API KEY</label>
              <input type="text" class="form-control" id="input-android-api-key" v-model="form.android_api_key">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label for="input-ios-api-key">iOS API KEY</label>
              <input type="text" class="form-control" id="input-ios-api-key" v-model="form.ios_api_key">
            </div>
          </div>
          <hr>
          <div class="row mb-3">
            <div class="col-12">
              <label for="input-subdomain">Σύνδεσμος Sub Domain</label>
              <input type="text" class="form-control" id="input-subdomain" v-model="form.subdomain">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label for="input-streaming">Streaming URL</label>
              <input type="text" class="form-control" id="input-streaming" v-model="form.streaming">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label for="input-rss-feed">Streaming URL</label>
              <input type="text" class="form-control" id="input-streaming" v-model="form.rss">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-cancel me-1"></i>Ακύρωση</button>
          <div v-html="this.config.button"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import Swal from "sweetalert2";
import errorHandle from "@/library/RequestErrorHandling";
import {validateBirthdate, validateEmail, validateString, validateVat} from "@/library/Validator";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css'

export default {
  name: "RadiosModalComponent",
  components: {vSelect},
  data() {
    return {
      modalRef: null,
      buttons: {
        create: '<button id="modal-confirm-button" class="btn btn-primary" onclick="store()"><i class="fas fa-save me-1"></i> Αποθήκευση</button>',
        update: '<button id="modal-confirm-button" class="btn btn-primary" onclick="update()"><i class="fas fa-rotate me-1"></i> Ενημέρωση</button>',
      },
      config: {
        title: '',
        button: null
      },
      clients: [],
      form: {
        client: null,
        name: '',
        email: '',
        created: '',
        expires: '',
        android_app_key: '',
        ios_app_key: '',
        android_api_key: '',
        ios_api_key: '',
        subdomain: '',
        streaming: '',
        rss: '',
      },
    }
  },
  created() {
    window.store = this.store;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.radiosModal);
  },
  methods: {
    prepareStore() {
      this.config.title = 'Προσθήκη νέου ραδιοφώνου';
      this.config.button = this.buttons.create;

      this.clearForm();
      this.filterClients('');
      this.openModal();
    },
    store() {
      if (this.validateForm() === false) {
        return;
      }

      let data = new FormData();
      let formData = this.form;

      // Loop through the form properties
      Object.entries(formData).forEach(([key, value]) => {
        data.append(key, value);
      });

      axios.post(apiRoutes.admin.clients.store, data, {
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('adminToken')}`}
      }).then(response => {
        if (response.status === 200) {
          this.$emit('data-modified');
          this.closeModal();
          Swal.fire('Επιτυχία', response.data.message, 'success');
        }
      }).catch(error => errorHandle(error));


    },
    edit(client) {
      this.config.title = 'Τροποποίηση πελάτη';
      this.config.button = this.buttons.update;

      this.form = client;
      this.form.type = (client.vat !== null ? '2' : '1');

      this.openModal();
    },
    update() {
      if (this.validateForm() === false) {
        return;
      }

      let data = {};
      let formData = this.form;

      // Loop through the form properties
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) {
          data[key] = value;
        }
      });

      axios.patch(apiRoutes.admin.clients.update, data, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('adminToken')}`, 'Accept': 'application/json',}
      }).then(response => {
        if (response.status === 200) {
          this.$emit('data-modified');
          this.closeModal();
          Swal.fire('Επιτυχία', response.data.message, 'success');
        }
      }).catch(error => errorHandle(error));
    },
    destroy(id) {
      Swal.fire({
        title: 'Προσοχή!',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει οριστικά τον πελάτη από το σύστημα. Εάν υπάρχουν ραδιόφωνα συνδεδεμένα με αυτό το χρήστη θα πρέπει πρώτα να διαγράψετε εκείνα. Θέλετε σίγουρα να συνεχίσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then(result => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.admin.clients.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
              timer: 600,
              showConfirmButton: false,
            })
            this.$emit('data-modified')
          }).catch(error => errorHandle(error))
        }
      });
    },
    filterClients(value) {
      axios.post(apiRoutes.admin.clients.filter, {
        'name': value,
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('adminToken')}`, Accept: 'application/json'},
      }).then((response) => {
        this.clients = [];
        response.data.forEach((item) => {
          const id = item.id;
          const label = item.name + ' ' + item.surname;
          this.clients.push({value: id, label: label})
        })
      }).catch((error) => errorHandle(error));
    },
    openModal() {
      this.modalRef.show();
    },
    closeModal() {
      this.modalRef.hide();
    },
    clearForm() {
      const currentDate = new Date();
      const nextYearDate = new Date();
      nextYearDate.setFullYear(currentDate.getFullYear() + 1); // Add one year to the current date

      this.form.client = null;
      this.form.name = '';
      this.form.email = '';
      this.form.created = currentDate.toLocaleDateString('en-CA');
      this.form.expires = nextYearDate.toLocaleDateString('en-CA');
      this.form.android_app_key = '';
      this.form.ios_app_key = '';
      this.form.android_api_key = '';
      this.form.ios_api_key = '';
      this.form.subdomain = '';
      this.form.streaming = '';
      this.form.rss = '';
    },
    validateForm(action) {
      let name = validateString(this.form.name, 3, 100)
      if (!name.success) {
        if (name.message === 'empty') {
          Swal.fire('Σφάλμα', 'Το όνομα είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (name.message === 'min' || name.message === 'max') {
          Swal.fire('Σφάλμα', 'Το όνομα πρέπει να αποτελείται από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let surname = validateString(this.form.surname, 3, 100)
      if (!surname.success) {
        if (surname.message === 'empty') {
          Swal.fire('Σφάλμα', 'Το επώνυμο είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (surname.message === 'min' || surname.message === 'max') {
          Swal.fire('Σφάλμα', 'Το επώνυμο πρέπει να αποτελείται από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let email = validateEmail(this.form.email)
      if (!email.success) {
        if (email.message === 'empty') {
          Swal.fire('Σφάλμα', 'Το email είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (email.message === 'invalid') {
          Swal.fire('Σφάλμα', 'Το email δεν είναι έγκυρο!', 'error')
          return false;
        }
      }

      let date = validateBirthdate(this.form.birthdate, 17, 100)
      if (!date.success) {
        if (date.message === 'empty') {
          Swal.fire('Σφάλμα', 'Η ημερομηνία γέννησης είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (date.message === 'pattern') {
          Swal.fire('Σφάλμα', 'Η ημερομηνία γέννησης πρέπει να είναι της μορφής YYYY-MM-DD!', 'error')
          return false;
        }
        if (date.message === 'min') {
          Swal.fire('Σφάλμα', 'Ο πελάτης δεν μπορεί να είναι κάτω από 17 χρονών!', 'error')
          return false;
        }
        if (date.message === 'max') {
          Swal.fire('Σφάλμα', 'Ο πελάτης δεν μπορεί να είναι πάνω απο 100 χρονών!', 'error')
          return false;
        }
      }

      let password = validateString(this.form.password, 3, 100)
      if (!password.success) {
        if (password.message === 'empty' && action === 'store') {
          Swal.fire('Σφάλμα', 'Ο κωδικός πρόσβασης είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if ((password.message === 'min' || password.message === 'max') && (action === 'update' && this.form.password.length > 0)) {
          Swal.fire('Σφάλμα', 'Ο κωδικός πρόσβασης πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let phone = validateString(this.form.phone, 9, 15)
      let mobile = validateString(this.form.mobile, 9, 15)

      if (!phone.success) {
        if (phone.message === 'empty' && mobile.message === 'empty') {
          Swal.fire('Σφάλμα', 'Τουλάχιστον ένα τηλέφωνο επικοινωνίας είναι υποχρεωτικό!', 'error')
          return false;
        }
        if (phone.message === 'min' || phone.message === 'max') {
          Swal.fire('Σφάλμα', 'Το τηλέφωνο πρέπει να είναι από 10 έως 15 χαρακτήρες!', 'error')
          return false;
        }
      }

      if (!mobile.success) {
        if (mobile.message === 'empty' && phone.message === 'empty') {
          Swal.fire('Σφάλμα', 'Τουλάχιστον ένα τηλέφωνο επικοινωνίας είναι υποχρεωτικό!', 'error')
          return false;
        }
        if (mobile.message === 'min' || mobile.message === 'max') {
          Swal.fire('Σφάλμα', 'Το κινητό πρέπει να είναι από 10 έως 15 χαρακτήρες!', 'error')
          return false;
        }
      }

      let city = validateString(this.form.city, 3, 100)
      if (!city.success) {
        if (city.message === 'empty') {
          Swal.fire('Σφάλμα', 'Η πόλη είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (city.message === 'min' || city.message === 'max') {
          Swal.fire('Σφάλμα', 'Η πόλη πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let address = validateString(this.form.address, 3, 100)
      if (!address.success) {
        if (address.message === 'empty') {
          Swal.fire('Σφάλμα', 'Η διεύθυνση είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (address.message === 'min' || address.message === 'max') {
          Swal.fire('Σφάλμα', 'Η διεύθυνση πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let area = validateString(this.form.area, 3, 100)
      if (!area.success) {
        if (area.message === 'empty') {
          Swal.fire('Σφάλμα', 'Η περιοχή είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (area.message === 'min' || area.message === 'max') {
          Swal.fire('Σφάλμα', 'Η περιοχή πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
          return false;
        }
      }

      let zip = validateString(this.form.zip, 3, 6)
      if (!zip.success) {
        if (zip.message === 'empty') {
          Swal.fire('Σφάλμα', 'Ο Τ.Κ. είναι υποχρεωτικό πεδίο!', 'error')
          return false;
        }
        if (zip.message === 'min' || zip.message === 'max') {
          Swal.fire('Σφάλμα', 'Ο Τ.Κ. πρέπει να είναι από 3 έως 6 χαρακτήρες!', 'error')
          return false;
        }
      }
      this.form.zip = this.form.zip.toString();

      if (this.form.type === '2') {
        let business = validateString(this.form.business, 3, 100)
        if (!business.success) {
          if (business.message === 'empty') {
            Swal.fire('Σφάλμα', 'Η επωνυμία της επιχείρησης είναι υποχρεωτικό πεδίο στους επαγγελματικούς λογαριασμούς!', 'error')
            return false;
          }
          if (business.message === 'min' || business.message === 'max') {
            Swal.fire('Σφάλμα', 'Η επωνυμία της επιχείρησης πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
            return false;
          }
        }

        let profession = validateString(this.form.profession, 3, 100)
        if (!profession.success) {
          if (profession.message === 'empty') {
            Swal.fire('Σφάλμα', 'Το επάγγελμα είναι υποχρεωτικό πεδίο στους επαγγελματικούς λογαριασμούς!', 'error')
            return false;
          }
          if (profession.message === 'min' || profession.message === 'max') {
            Swal.fire('Σφάλμα', 'Το επάγγελμα πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
            return false;
          }
        }

        let vat = validateVat(this.form.vat)
        if (!vat.success) {
          if (vat.message === 'empty') {
            Swal.fire('Σφάλμα', 'Το Α.Φ.Μ. είναι υποχρεωτικό πεδίο στους επαγγελματικούς λογαριασμούς!', 'error')
            return false;
          }
          if (vat.message === 'digits') {
            Swal.fire('Σφάλμα', 'Το Α.Φ.Μ. πρέπει να αποτελείται από 9 ψηφία!', 'error')
            return false;
          }
          if (vat.message === 'invalid') {
            Swal.fire('Σφάλμα', 'Το Α.Φ.Μ. δεν είναι έγκυρο!', 'error')
            return false;
          }
        }

        let irs = validateString(this.form.irs, 3, 100)
        if (!irs.success) {
          if (irs.message === 'empty') {
            Swal.fire('Σφάλμα', 'Η Δ.Ο.Υ. είναι υποχρεωτικό πεδίο στους επαγγελματικούς λογαριασμούς!', 'error')
            return false;
          }
          if (irs.message === 'min' || irs.message === 'max') {
            Swal.fire('Σφάλμα', 'Η Δ.Ο.Υ. πρέπει να είναι από 3 έως 100 χαρακτήρες!', 'error')
            return false;
          }
        }
      }

    },
  }
}
</script>

<style scoped>

</style>