import Swal from "sweetalert2";

const errorHandle = (error) => {
    if (error.response) {
        if (error.response.status === 422) {
            Swal.fire('Σφάλμα', error.response.data.errors[Object.keys(error.response.data.errors)[0]][0], 'error').then(() => {
            })
            return;
        }
        if (error.response.status === 401 || error.response.status === 403) {
            Swal.fire('Σφάλμα Διαπιστευτηρίων', 'Δεν έχετε τα απαραίτητα δικαιώματα για να εκτελέσετε αυτήν την ενέργεια. Πραγματοποιείται αυτόματη αποσύνδεση από το σύστημα.', 'error').then(() => {
                localStorage.clear();
                location.reload();
            });
            return;
        }
        Swal.fire('Πρόβλημα στο server', 'Προέκυψε ένα απροσδιόριστο πρόβλημα στον server. Παρακαλούμε συνδεθείτε ξανά στο σύστημα. Ζητούμε συγνώμη για την αναστάτωση.', 'error').then(() => {
            localStorage.clear();
            location.reload();
        });
    }

}

export default errorHandle;