<template>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">Copyright &copy; My Web Radio {{ date }}</div>
        <div>
          <a href="#"><i class="fa-regular fa-paper-plane me-1"></i>Επικοινωνίστε με τους διαχειριστές</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      dateStarted: 2024,
      date: null
    }
  },
  created() {
    const currentYear = new Date().getFullYear();

    this.date = currentYear;
    if (currentYear > this.dateStarted) {
      this.date = this.dateStarted + ' - ' + currentYear;
    }
  }
}
</script>


<style scoped>

</style>