<template>
  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
      <div class="nav">
        <div class="sb-sidenav-menu-heading">ΠΡΟΕΠΙΣΚΟΠΙΣΗ</div>
        <router-link :to="{name: 'home'}" :class="'nav-link' + (this.activePage === 'home' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-house"></i></div>
          Αρχική
        </router-link>
<!--        <div class="sb-sidenav-menu-heading">Interface</div>-->
<!--        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">-->
<!--          <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>-->
<!--          Layouts-->
<!--          <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>-->
<!--        </a>-->
<!--        <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">-->
<!--          <nav class="sb-sidenav-menu-nested nav">-->
<!--            <a class="nav-link" href="#">Static Navigation</a>-->
<!--            <a class="nav-link" href="#">Light Sidenav</a>-->
<!--          </nav>-->
<!--        </div>-->
<!--        <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">-->
<!--          <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>-->
<!--          Pages-->
<!--          <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>-->
<!--        </a>-->
<!--        <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">-->
<!--          <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">-->
<!--            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">-->
<!--              Authentication-->
<!--              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>-->
<!--            </a>-->
<!--            <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">-->
<!--              <nav class="sb-sidenav-menu-nested nav">-->
<!--                <a class="nav-link" href="#">Login</a>-->
<!--                <a class="nav-link" href="#">Register</a>-->
<!--                <a class="nav-link" href="#">Forgot Password</a>-->
<!--              </nav>-->
<!--            </div>-->
<!--            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">-->
<!--              Error-->
<!--              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>-->
<!--            </a>-->
<!--            <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">-->
<!--              <nav class="sb-sidenav-menu-nested nav">-->
<!--                <a class="nav-link" href="#">401 Page</a>-->
<!--                <a class="nav-link" href="#">404 Page</a>-->
<!--                <a class="nav-link" href="#">500 Page</a>-->
<!--              </nav>-->
<!--            </div>-->
<!--          </nav>-->
<!--        </div>-->
        <div class="sb-sidenav-menu-heading">ΡΥΘΜΙΣΕΙΣ</div>
        <router-link :to="{name: 'radios'}" :class="'nav-link' + (activePage === 'radios' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-tower-broadcast"></i></div>Οι σταθμοί μου
        </router-link>
      </div>
    </div>
    <div class="sb-sidenav-footer text-center">
      <div class="small mb-2">Created & Hosted by:</div>
      <a href="https://gr-net.gr" target="_blank"><img src="../assets/img/gr-net-white.webp" alt="GR-NET" class="img-fluid"></a>
    </div>
  </nav>
</template>

<style scoped>

</style>

<script>

export default {
  name: "SidebarComponent",
  props: ['activePage']
}

</script>