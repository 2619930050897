<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <!-- Navbar Brand-->
    <a class="navbar-brand ps-3" href="#"><i class="fa-solid fa-radio me-2"></i>My Web Radio</a>
    <!-- Sidebar Toggle-->
    <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i
        class="fas fa-bars"></i></button>
    <!-- Navbar Search-->
    <div class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></div>
    <!-- Navbar-->
    <span class="text-light">{{ administratorName }}</span>
    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
           aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
          <li @click="prepareLogout">
            <div class="dropdown-item"><i class="fa-solid fa-right-from-bracket me-1"></i>Αποσύνδεση</div>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import Swal from "sweetalert2";
import errorHandle from "@/library/RequestErrorHandling";

export default {
  name: "NavbarComponent",
  data() {
    return {
      administratorName: ""
    }
  },
  mounted() {
    this.administratorName = localStorage.getItem("username");
  },
  methods: {
    prepareLogout() {
      Swal.fire({
        title: "Προσοχή",
        html: "Πρόκειται να αποσυνδεθείτε από το διαχειριστικό! Επιθυμείτε σίγουρα την αποσύνδεση;",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Αποσύνδεση",
        cancelButtonText: "Επιστροφή",
      }).then((r) => {
        if (r.isConfirmed) {
          this.logout()
        }
      });
    },
    logout() {
      axios.post(apiRoutes.auth.logout, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        }
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Επιτυχία",
            html: "Αποσυνδεθήκατε με επιτυχία",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          })
          localStorage.clear();
          this.$router.push({name: 'login'});
        }
      }).catch((error) => errorHandle(error));
    }
  }
}
</script>

<style scoped>
.dropdown-item {
  cursor: pointer;
}
</style>