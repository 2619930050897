<template>
  <AuthLayout :admin="false">
    <div class="card shadow-lg">
      <div class="card-body p-5">
        <h1 class="fs-4 card-title fw-bold mb-4">Επαναφορά Κωδικού</h1>
        <form>

          <div class="mb-3">
            <label class="mb-2 text-muted" for="email">Διεύθυνση Email</label>
            <input @keydown.enter.prevent="forgotPassword" id="email" type="email" class="form-control" name="email" value=""
                   v-model="email"
                   required autofocus>
          </div>

          <div class="d-grid gap-2 mx-auto">
            <button type="button" class="btn btn-primary btn-block" @click="forgotPassword">Αποστολή Αιτήματος</button>
          </div>
        </form>
      </div>
      <div class="card-footer text-center py-3">
        <div class="small">Επιστροφή στην <router-link :to="{name: 'login'}">Οθόνη Σύνδεσης</router-link></div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "ForgotPasswordView",
  components: {AuthLayout},
  data() {
    return {
      dateStarted: 2024,
      date: null,
      email: ''
    }
  },
  mounted() {
    document.body.classList.add('bg-dark');

    const currentYear = new Date().getFullYear();

    this.date = currentYear;
    if (currentYear > this.dateStarted) {
      this.date = this.dateStarted + ' - ' + currentYear;
    }
  },
  methods: {
    forgotPassword() {

      if (!this.email) {
        return;
      }

      axios.post(apiRoutes.auth.forgotPasswordRequest, {email: this.email}, {
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')},
      }).then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Επιτυχία',
          text: response.data.message
        })
        this.$router.push({name: 'login'});
      }).catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: 'Κάτι πήγε στραβά'
          })
        } else if (error.response.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: error.response.responseJSON.errors[Object.keys(error.response.responseJSON.errors)[0]][0]
          })
        } else if (error.response.status === 429) {
          Swal.fire({
            icon: 'error',
            title: 'Σφάλμα',
            text: 'Υπερβολικά πολλά αιτήματα! Προσπαθήστε μετά από μερικά λεπτά!'
          })
        }
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  }
}
</script>

<style scoped>

</style>