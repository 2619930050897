<template>
  <ClientLayout active-page="radios">
      <RadiosComponent :client-id="clientId"/>
  </ClientLayout>
</template>

<script>
import ClientLayout from "@/layouts/ClientLayout.vue";
import RadiosComponent from "@/views/Radios/RadiosComponent.vue";
import isTokenValid from "@/library/CheckToken";
import isAdminTokenValid from "@/library/CheckAdminToken";

export default {
  name: "RadiosView",
  components: {ClientLayout, RadiosComponent},
  beforeRouteEnter(to, from, next) {
    // First check if the client token is valid
    isTokenValid().then(isValid => {
      if (isValid) {
        next();  // Allow access if the client token is valid
        return;
      }

      // If client token is not valid, check the admin token
      isAdminTokenValid().then(isAdminValid => {
        if (isAdminValid) {
          next();  // Allow access if the admin token is valid
          return;
        }

        // If both tokens are invalid, redirect to the appropriate login page
        next({ name: "adminLogin" }); // Redirect admin to login
      }).catch(() => {
        next({ name: "adminLogin" }); // On admin token validation error, redirect to admin login
      });

    }).catch(() => {
      next({ name: "login" }); // On client token validation error, redirect to client login
    });
  },
  created() {
    this.clientId = localStorage.getItem("clientId");
  },
  data() {
    return {
      clientId: null,
    }
  }
}
</script>

<style scoped>

</style>