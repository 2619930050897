const apiURL = process.env.VUE_APP_API_URL

const apiRoutes = {
    auth: {
        checkToken: `${apiURL}/checkToken`,
        checkAdminToken: `${apiURL}/checkAdminToken`,

        login: `${apiURL}/login`,
        logout: `${apiURL}/logout`,

        adminLogin: `${apiURL}/gr-net-BOaDm/login`,
        adminLogout: `${apiURL}/gr-net-BOaDm/logout`,

        checkPasswordRequest: `${apiURL}/password/request/check`,
        forgotPasswordRequest: `${apiURL}/password/request/check`,
    },
    radios: {
        get: `${apiURL}/radios/get`,
        updateProgram: `${apiURL}/radios/program/update`,

        getBanners: `${apiURL}/radios/banners/get`,
        updateBanner: `${apiURL}/radios/banners/update`,
        removeBanner: `${apiURL}/radios/banners/remove`,
    },
    admin: {
        clients: {
            fetch: `${apiURL}/gr-net-BOaDm/clients/fetch`,
            store: `${apiURL}/gr-net-BOaDm/clients/store`,
            update: `${apiURL}/gr-net-BOaDm/clients/update`,
            destroy: `${apiURL}/gr-net-BOaDm/clients/destroy`,
            filter: `${apiURL}/gr-net-BOaDm/clients/filter`,
        },
        radios: {
            fetch: `${apiURL}/gr-net-BOaDm/radios/fetch`,
            store: `${apiURL}/gr-net-BOaDm/radios/store`,
            update: `${apiURL}/gr-net-BOaDm/radios/update`,
            destroy: `${apiURL}/gr-net-BOaDm/radios/destroy`,
        }
    }
};

export default apiRoutes