<template>
  <AuthLayout :admin="true">
  <div class="card shadow-lg">
    <div class="card-body p-5">
      <h1 class="fs-4 card-title fw-bold mb-4">Σύνδεση ως διαχειριστής</h1>
      <form method="POST" class="needs-validation" novalidate="" autocomplete="off">
        <div class="mb-3">
          <label class="mb-2 text-muted" for="email">Διεύθυνση E-Mail</label>
          <input @keydown.enter.prevent="login" id="email" type="email" :class="'form-control' + (form.email.error.length > 0 ? ' is-invalid' : '')" name="email" v-model="form.email.value" required autofocus>
          <div class="invalid-feedback" v-if="form.email.error.length > 0">{{ form.email.error }}</div>
        </div>

        <div class="mb-3">
          <div class="mb-2 w-100">
            <label class="text-muted" for="password">Κωδικός Πρόσβασης</label>
          </div>
          <input @keydown.enter.prevent="login" id="password" type="password" :class="'form-control' + (form.password.error.length > 0 ? ' is-invalid' : '')" name="password" v-model="form.password.value" required>
          <div class="invalid-feedback" v-if="form.password.error.length > 0">{{ form.password.error }}</div>
        </div>

        <div class="d-grid gap-2 mx-auto">
          <button @click="login" class="btn btn-primary" type="button">Σύνδεση</button>
        </div>
      </form>
    </div>
    <div class="card-footer text-center py-3">
      <div class="small"><router-link :to="{name: 'login'}">Συνδεθείτε ως πελάτης</router-link></div>
    </div>
  </div>
  </AuthLayout>
</template>

<script>
import axios from "axios";
// import errorHandle from "@/assets/js/Library/ErrorHandle";
import Swal from "sweetalert2";
import apiRoutes from "@/router/apiRoutes";
import AuthLayout from "@/layouts/AuthLayout.vue";
import errorHandle from "@/library/RequestErrorHandling";
import isAdminTokenValid from "@/library/CheckAdminToken";

export default {
  name: 'LoginView',
  components: {AuthLayout},
  meta: {guest: true},
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('adminToken') !== null && localStorage.getItem('username') !== null && isAdminTokenValid()) {
      next({name: "adminDashboard"});
      return;
    }
    next();
  },
  data() {
    return {
      form: {
        email: {value: '', error: ''},
        password: {value: '', error: ''},
      }
    }
  },
  mounted() {
    document.body.classList.add('bg-dark');
  },
  methods: {
    login() {
      // Clear form library
      this.form.email.error = '';
      this.form.password.error = '';

      if (this.form.email.value.length === 0) {
        this.form.email.error = 'Η διεύθυνση e-mail είναι υποχρεωτικό πεδίο';
        return;
      }
      const mailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!mailRegex.test(this.form.email.value)) {
        this.form.email.error = 'Η διεύθυνση e-mail δεν είναι έγκυρη';
        return;
      }
      if (this.form.password.value.length === 0) {
        this.form.password.error = 'Ο κωδικός πρόσβασης είναι υποχρεωτικό πεδίο';
        return;
      }

      axios.post(apiRoutes.auth.adminLogin, {
        email: this.form.email.value,
        password: this.form.password.value,
      }, {
        headers: {Accept: 'application/json'}
      }).then((response) => {
        if (response.status === 200 && response.data.data.token && response.data.data.username) {
          localStorage.clear();
          localStorage.setItem('adminToken', response.data.data.token)
          localStorage.setItem('username', response.data.data.username)
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            timer: 600,
            showConfirmButton: false,
            allowEnterKey: true,
          }).then(() => {
            this.$router.push({name: 'adminDashboard'});
          });
        }
      }).catch((error) => errorHandle(error));
    }
  },
}
</script>

<style scoped>
</style>