<template>
  <NavbarComponent/>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <SidebarComponent :active-page="activePage"/>
    </div>
    <div id="layoutSidenav_content">
      <main>
        <slot/>
      </main>
      <FooterComponent/>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import SidebarComponent from "@/components/SidebarComponent.vue";

import "/src/assets/js/fontawesome.all"
import "bootstrap/dist/js/bootstrap.min"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "/src/assets/js/bootstrap.bundle.min.js"

export default {
  name: 'ClientLayout',
  props: ['activePage'],
  components: {
    NavbarComponent, FooterComponent, SidebarComponent
  },
  created() {
    document.body.classList.remove('bg-dark');
  },
  mounted() {
    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
    }

  }
}

</script>

<style scoped>
  @import "/src/assets/css/styles.css";
</style>