<template>
  <div class="row">
    <div class="col">
      <span v-if="dataLength > 0">Προβολή σελίδας {{ pagingObject.current_page }} από {{ pagingObject.last_page }} σελίδες συνολικά</span>
      <span v-if="searchValue">(μέσα από έρευνα σε {{ pagingObject.totalRecords }} συνολικά εγγραφές)</span>
    </div>
    <div class="col d-flex justify-content-end">
      <PaginatorComponent :total-pages="pagingObject.last_page" :current-page="pagingObject.current_page" @page-changed="changePage"/>
    </div>
  </div>
</template>

<script>
import PaginatorComponent from "@/components/TableComponents/TablePaginatorComponent.vue";

export default {
  name: "TableFooterComponent",
  components: {PaginatorComponent},
  props: {
    dataLength: {
      type: Number,
      required: true
    },
    pagingObject: {
      type: Object,
      required: true
    },
    searchValue: {
      type: String,
      required: true
    }
  },

  methods: {
    changePage(pageNumber) {
      // Emit an event to notify the parent component to change the current page
      this.$emit('page-changed', pageNumber);
    }
  }
};
</script>

<style>
/* Add your pagination styles here */
</style>
