<template>
  <AdminLayout active-page="adminClients">
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-users me-2"></i>Πελάτες</h1>
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item active"><i class="fas fa-users me-1"></i>Πελάτες</li>
      </ol>

       <ClientsTableComponent/>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import ClientsTableComponent from "@/views/Admin/Clients/ClientsTableComponent.vue";
import isAdminTokenValid from "@/library/CheckAdminToken";

export default {
  name: "ClientsView" ,
  components: {ClientsTableComponent, AdminLayout},
  beforeRouteEnter(to, from, next) {
    isAdminTokenValid().then(isValid => {
      if (!isValid) {
        next({name: "adminLogin"});
        return;
      }
      next();

    }).catch(() => {
      next({name: "adminLogin"});
    });
  },
}
</script>

<style scoped>

</style>