<template>
  <div class="container-fluid px-4">
    <h1 class="mt-4"><i class="fas fa-tower-broadcast me-2"></i>Οι σταθμοί μου</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">
        <router-link :to="{name: 'home'}"><i class="fas fa-house me-1"></i>Αρχική</router-link>
      </li>
      <li :class="'breadcrumb-item' + (selectedRadio === null ? ' active' : '')">
        <span v-if="selectedRadio === null"><i class="fas fa-tower-broadcast me-1"></i>Οι σταθμοί μου</span>
        <span v-else><a href="#" @click="deselectRadio()"><i class="fas fa-tower-broadcast me-1"></i>Οι σταθμοί μου</a></span>
      </li>
      <li class="breadcrumb-item active" v-if="selectedRadio !== null"><i
          class="fas fa-satellite-dish me-1"></i>{{ selectedRadio.name }}
      </li>
    </ol>

    <div v-if="selectedRadio === null">
      <h4>Επιλέξτε το σταθμό που θέλετε να διαχειριστείτε:</h4>

      <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 mt-5">
        <div class="col mb-5" v-for="r in radios" :key="r.id">
          <div class="card radio-card text-center tooltip-container tooltip-trigger" @click="selectRadio(r)">
            <div class="card-header">{{ r.name }}</div>
            <div class="card-body">
              <p class="card-text">E-mail: {{ r.email }}</p>
              <p class="card-text">Δημιουργήθηκε: {{ r.created }}</p>
              <p class="card-text">Λήγει στις: {{ r.expires }}</p>
            </div>
            <div class="card-footer text-muted">Κατάσταση:
              <span class="badge rounded-pill bg-danger" v-if="r.expired">Έληξε</span>
              <span class="badge rounded-pill bg-warning" v-else-if="r.expire_alert <= 3">Λήγει σε {{ r.expire_alert }}
                  <span v-if="r.expire_alert > 1">ημέρες</span>
                  <span v-else>ημέρα</span>
                </span>
              <span class="badge rounded-pill bg-success" v-else>Ενεργό</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-else>

      <ProgramComponent :radio="selectedRadio"/>

      <BannersComponent :radio="selectedRadio"/>

    </div>

  </div>
</template>

<script>
import BannersComponent from "@/views/Radios/BannersComponent.vue";
import ProgramComponent from "@/views/Radios/ProgramComponent.vue";
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import errorHandle from "@/library/RequestErrorHandling";

export default {
  name: "RadiosComponent" ,
  components: {ProgramComponent, BannersComponent},
  props: ['client-id'],
  data() {
    return {
      radios: [],
      selectedRadio: null,
    }
  },
  mounted() {
    this.fetchRadios();
  },
  methods: {
    fetchRadios() {
      axios.post(apiRoutes.radios.get, {'clientId': this.clientId}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: "application/json",
        }
      }).then((response) => {
        this.radios = response.data;
      }).catch((error) => errorHandle(error));
    },
    selectRadio(radio) {
      this.selectedRadio = radio;
    },
    deselectRadio() {
      this.selectedRadio = null;
    }
  }
}
</script>

<style scoped>
.radio-card {
  cursor: pointer;
}
</style>