import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from "@/views/Auth/LoginView.vue";
import ForgotPasswordView from "@/views/Auth/ForgotPasswordView.vue";
import AdminLoginView from "@/views/Auth/AdminLoginView.vue";
import RadiosView from "@/views/Radios/RadiosView.vue";

import DashboardView from "@/views/Admin/Dashboard/AdminDashboardView.vue";
import AdminRadiosView from "@/views/Admin/Radios/AdminRadiosView.vue";
import ClientsView from "@/views/Admin/Clients/ClientsView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes = [
    {path: '/login', name: 'login', component: LoginView},
    {path: '/gr-net-BOaDm/login', name: 'adminLogin', component: AdminLoginView},

    {path: '/password/forgot', name: 'forgotPassword', component: ForgotPasswordView},

    {path: '/', name: 'home', component: HomeView},
    {path: '/radios', name: 'radios', component: RadiosView},

    {path: '/gr-net-BOaDm', name: 'adminDashboard', component: DashboardView},
    {path: '/gr-net-BOaDm/clients', name: 'adminClients', component: ClientsView},
    {path: '/gr-net-BOaDm/radios', name: 'adminRadios', component: AdminRadiosView},

    // 404 fallback route - make sure this is at the end
    {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
