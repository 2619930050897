<template>
  <div>
    <!-- Pagination -->
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">

        <!-- Previous Page Button -->
        <li class="page-item" v-if="currentPage !== 1">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="gotoPage(1)">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        <!-- Previous Page Button -->
        <li class="page-item" v-if="currentPage !== 1">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="gotoPage(currentPage - 1)">
            <span aria-hidden="true">&lsaquo;</span>
          </a>
        </li>

        <!-- Page Numbers -->
        <li v-for="pageNumber in displayedPages" :key="pageNumber" :class="{ active: pageNumber === currentPage }"
            class="page-item">
          <a v-if="pageNumber !== currentPage" class="page-link" href="javascript:void(0)" @click.prevent="gotoPage(pageNumber)">{{ pageNumber }}</a>
          <a v-else class="page-link" href="javascript:void(0)">{{ pageNumber }}</a>
        </li>

        <!-- Next Page Button -->
        <li class="page-item" v-if="currentPage !== totalPages">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="gotoPage(currentPage + 1)">
            <span aria-hidden="true">&rsaquo;</span>
          </a>
        </li>

        <!-- Last Page Button -->
        <li class="page-item" v-if="currentPage !== totalPages">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="gotoPage(totalPages)">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>

      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TablePaginatorComponent",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      paginator: {},
    }
  },
  computed: {
    displayedPages() {
      const minPage = Math.max(1, this.currentPage - 2);
      const maxPage = Math.min(this.totalPages, this.currentPage + 2);
      const numPages = maxPage - minPage + 1;
      return Array.from({length: numPages}, (_, i) => minPage + i);
    }
  },
  methods: {
    gotoPage(pageNumber) {
      // Emit an event to notify the parent component to change the current page
      this.$emit('page-changed', pageNumber);
    }
  }
};
</script>

<style>
/* Add your pagination styles here */
</style>
