<template>
  <section class="h-100">
    <div class="container h-100">
      <div class="row justify-content-sm-center h-100">
        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <div class="text-center my-5">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../assets/img/logo.png" alt="LOGO" class="img-fluid">
              </div>
              <div class="col-9">
                <h1 class="text-white mt-3">My Web Radio</h1>
                <h6 v-if="admin === true" class="text-white fst-italic mt-3">Administrator</h6>
              </div>
            </div>
          </div>

          <slot/>

          <div class="text-center mt-5 text-muted">
            Copyright &copy; {{ date }} &mdash; Powered by <a href="https://gr-net.gr" target="_blank">GR-NET E.E.</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AuthLayout",
  props: ['admin'],
  data() {
    return {
      dateStarted: 2024,
      date: null
    }
  },
  created() {
    document.body.classList.add('bg-dark');
  },
  mounted() {
    const currentYear = new Date().getFullYear();

    this.date = currentYear;
    if (currentYear > this.dateStarted) {
      this.date = this.dateStarted + ' - ' + currentYear;
    }
  }
}
</script>

<style scoped>

</style>