<template>
  <ClientLayout active-page="home">
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-house me-2"></i>Αρχική</h1>
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item active"><i class="fas fa-house me-1"></i>Αρχική</li>
      </ol>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card bg-primary text-white mb-4">
            <div class="card-body">Primary Card</div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="#">View Details</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-warning text-white mb-4">
            <div class="card-body">Warning Card</div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="#">View Details</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-success text-white mb-4">
            <div class="card-body">Success Card</div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="#">View Details</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-danger text-white mb-4">
            <div class="card-body">Danger Card</div>
            <div class="card-footer d-flex align-items-center justify-content-between">
              <a class="small text-white stretched-link" href="#">View Details</a>
              <div class="small text-white"><i class="fas fa-angle-right"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <AreaChartComponent/>
        <BarChartComponent/>
      </div>
    </div>
  </ClientLayout>
</template>

<script>
// @ is an alias to /src

import ClientLayout from "@/layouts/ClientLayout.vue";
import AreaChartComponent from "@/components/Charts/AreaChartComponent.vue";
import BarChartComponent from "@/components/Charts/BarChartComponent.vue";

export default {
  name: 'DashboardView',
  components: {BarChartComponent, AreaChartComponent, ClientLayout},
}
</script>
