<template>
  <div class="card">

    <div class="card-header">
      <div class="row">
        <div class="col"><i class="fas fa-list me-2"></i>Πρόγραμμα Σταθμού</div>
        <div class="col">
          <button v-if="mode === 'read'" class="btn btn-outline-dark float-end" @click="editProgram"><i class="fas fa-edit me-1"></i>Τροποποίηση</button>
          <button v-if="mode === 'edit'" class="btn btn-outline-dark float-end" @click="createBroadcast"><i class="fas fa-plus me-1"></i>Προσθήκη εκπομπής</button>
          <button v-if="mode === 'edit'" class="btn btn-outline-dark float-end me-2" @click="updateProgram"><i class="fas fa-save me-1"></i>Αποθήκευση</button>
          <button v-if="mode === 'edit'" class="btn btn-outline-dark float-end me-2" @click="cancelEditProgram"><i class="fas fa-cancel me-1"></i>Ακύρωση</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs" id="week" role="tablist">
        <li class="nav-item" role="presentation"><button class="nav-link active" id="monday-tab" data-bs-toggle="tab" data-bs-target="#monday" type="button" role="tab" aria-controls="monday" aria-selected="true" @click="selectTab('monday')">Δευτέρα</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="tuesday-tab" data-bs-toggle="tab" data-bs-target="#tuesday" type="button" role="tab" aria-controls="tuesday" aria-selected="false" @click="selectTab('tuesday')">Τρίτη</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="wednesday-tab" data-bs-toggle="tab" data-bs-target="#wednesday" type="button" role="tab" aria-controls="wednesday" aria-selected="false" @click="selectTab('wednesday')">Τετάρτη</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="thursday-tab" data-bs-toggle="tab" data-bs-target="#thursday" type="button" role="tab" aria-controls="thursday" aria-selected="false" @click="selectTab('thursday')">Πέμπτη</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="friday-tab" data-bs-toggle="tab" data-bs-target="#friday" type="button" role="tab" aria-controls="friday" aria-selected="false" @click="selectTab('friday')">Παρασκευή</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="saturday-tab" data-bs-toggle="tab" data-bs-target="#saturday" type="button" role="tab" aria-controls="saturday" aria-selected="false" @click="selectTab('saturday')">Σάββατο</button></li>
        <li class="nav-item" role="presentation"><button class="nav-link" id="sunday-tab" data-bs-toggle="tab" data-bs-target="#sunday" type="button" role="tab" aria-controls="sunday" aria-selected="false" @click="selectTab('sunday')">Κυριακή</button></li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="monday" role="tabpanel" aria-labelledby="monday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.monday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.monday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="tuesday" role="tabpanel" aria-labelledby="tuesday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.tuesday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.tuesday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="wednesday" role="tabpanel" aria-labelledby="wednesday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.wednesday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.wednesday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="thursday" role="tabpanel" aria-labelledby="thursday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.thursday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.thursday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="friday" role="tabpanel" aria-labelledby="friday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.friday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.friday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="saturday" role="tabpanel" aria-labelledby="saturday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.saturday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.saturday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="sunday" role="tabpanel" aria-labelledby="sunday-tab">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="text-center w-25"><i class="fas fa-clock me-2"></i>Ώρες</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Όνομα</th>
              <th scope="col" class="text-center"><i class="fas fa-quote-right me-2"></i>Περιγραφή</th>
              <th v-if="mode === 'edit'" scope="col" class="text-center"><i class="fas fa-lg fa-cogs"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!program.sunday && mode !== 'create'">
              <td colspan="4" class="text-center">&mdash;</td>
            </tr>
            <tr v-for="(p, index) in program.sunday" :key="p.id">
              <td class="text-center">
                <div class="row">
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_start" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_start }}</span>
                  </div>
                  <div class="col-2">&mdash;</div>
                  <div class="col-5">
                    <flat-pickr v-if="mode === 'edit' || mode === 'create'" class="form-control time-input" v-model="p.time_end" :config="flatpickerConfig"/>
                    <span v-else>{{ p.time_end }}</span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.name">
                <span v-else>{{ p.name }}</span>
              </td>
              <td class="text-center">
                <input v-if="mode === 'edit' || mode === 'create'" type="text" class="form-control" v-model="p.description">
                <span v-else>{{ p.description }}</span>
              </td>
              <td v-if="mode === 'edit'" class="text-center">
                <button @click="removeBroadcast(index)" class="btn btn-outline-dark"><i class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import Swal from "sweetalert2";

import "flatpickr/dist/flatpickr.min.css";
import {Greek} from "flatpickr/dist/l10n/gr";

export default {
  name: "ProgramComponent",
  components: {flatPickr},
  props: ['radio'],
  data() {
    return {
      initialProgram: null,
      program: null,
      day: 'monday',
      mode: 'read',
      flatpickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        locale: Greek
      },
    }
  },
  created() {
    this.program = this.radio.program
    this.initialProgram = JSON.parse(JSON.stringify(this.program));
  },
  mounted() {

  },
  methods: {
    selectTab(day) {
      this.day = day;
    },
    editProgram() {
      this.mode = "edit"
    },
    cancelEditProgram() {
      this.program = JSON.parse(JSON.stringify(this.initialProgram));
      this.mode = "read";
    },
    createBroadcast() {
      if (!this.program[this.day]) {
        this.program[this.day] = [];
      }

      this.program[this.day].push({
        "name": "",
        "description": "",
        "time_start": "",
        "time_end": ""
      });

    },
    removeBroadcast(index) {
      this.program[this.day].splice(index, 1);
    },
    updateProgram() {
      const data = new FormData();
      data.append('radio_id', this.radio.id)

      let index = 0;
      Object.keys(this.program).forEach((day) => {
        this.program[day].forEach((broadcast) => {
          data.append(`day[${index}]`, day);
          data.append(`time_start[${index}]`, broadcast.time_start);
          data.append(`time_end[${index}]`, broadcast.time_end);
          data.append(`name[${index}]`, broadcast.name);
          data.append(`description[${index}]`, broadcast.description);
          index++;
        });
      });
      axios.post(apiRoutes.radios.updateProgram, data, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Accept': 'application/json'}
      }).then((response) => {
        Swal.fire('Επιτυχία!', response.data.message, 'success')
        this.mode = 'read';
      });
    },
  }
}
</script>


<style scoped>

</style>