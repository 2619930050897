import axios from "axios";
import apiRoutes from "@/router/apiRoutes";

const checkTokenValidity = async () => {
    try {
        const response = await axios.get(apiRoutes.auth.checkToken, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.status === 200;
    } catch (error) {
        localStorage.clear();
        return false;
    }
}

const isTokenValid = async () => {
    return await checkTokenValidity()
}

export default isTokenValid