<template>
  <AuthLayout :admin="false">
    <div class="card shadow-lg">
      <div class="card-body p-5">
        <h1 class="fs-4 card-title fw-bold mb-4">Σύνδεση</h1>
        <form method="POST" class="needs-validation" novalidate="" autocomplete="off">
          <div class="mb-3">
            <label class="mb-2 text-muted" for="email">Διεύθυνση E-Mail</label>
            <input @keydown.enter.prevent="login" id="email" type="email" :class="'form-control' + (form.email.error.length > 0 ? ' is-invalid' : '')" name="email" v-model="form.email.value" required autofocus>
            <div class="invalid-feedback" v-if="form.email.error.length > 0">{{ form.email.error }}</div>
          </div>

          <div class="mb-3">
            <div class="mb-2 w-100">
              <label class="text-muted" for="password">Κωδικός Πρόσβασης</label>
            </div>
            <input @keydown.enter.prevent="login" id="password" type="password" :class="'form-control' + (form.password.error.length > 0 ? ' is-invalid' : '')" name="password" v-model="form.password.value" required>
            <div class="invalid-feedback" v-if="form.password.error.length > 0">{{ form.password.error }}</div>
          </div>

          <div class="d-grid gap-2 mx-auto">
            <button @click="login" class="btn btn-primary" type="button">Σύνδεση</button>
          </div>
        </form>
      </div>
      <div class="card-footer text-center py-3">
        <div class="small"><router-link :to="{name: 'forgotPassword'}">Ξεχάσατε τον κωδικό σας;</router-link></div>
      </div>
    </div>

  </AuthLayout>
</template>

<script>
import axios from "axios";
// import errorHandle from "@/assets/js/Library/ErrorHandle";
import Swal from "sweetalert2";
import apiRoutes from "@/router/apiRoutes";
import AuthLayout from "@/layouts/AuthLayout.vue";
import router from "@/router";

export default {
  name: 'LoginView',
  components: {AuthLayout},
  beforeRouteEnter(to, from, next) {
    const token = localStorage.getItem("token");

    if (token !== undefined && token !== null) {
      next({ name: "home" });  // Redirects and updates the URL
    } else {
      next();  // Proceed with mounting the login view if no token is present
    }
  },
  data() {
    return {
      form: {
        email: {value: '', error: ''},
        password: {value: '', error: ''},
      }
    }
  },
  methods: {
    login() {
      // Clear form library
      this.form.email.error = '';
      this.form.password.error = '';

      if (this.form.email.value.length === 0) {
        this.form.email.error = 'Η διεύθυνση e-mail είναι υποχρεωτικό πεδίο';
        return;
      }
      const mailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!mailRegex.test(this.form.email.value)) {
        this.form.email.error = 'Η διεύθυνση e-mail δεν είναι έγκυρη';
        return;
      }
      if (this.form.password.value.length === 0) {
        this.form.password.error = 'Ο κωδικός πρόσβασης είναι υποχρεωτικό πεδίο';
        return;
      }

      const data = new FormData;
      data.append('email', this.form.email.value);
      data.append('password', this.form.password.value);

      axios.post(apiRoutes.auth.login, data, {
        headers: {Accept: 'application/json'}
      }).then((response) => {
        if (!response.data.token || !response.data.username) {
          Swal.fire('Αποτυχία', 'Προέκυψε ένα πρόβλημα στο διακομιστή', 'error')
          return;
        }

        localStorage.clear();
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('id', response.data.id)
        localStorage.setItem('username', response.data.username)

        Swal.fire({
          title: 'Επιτυχία',
          icon: 'success',
          text: response.data.message,
          timer: 600,
          showConfirmButton: false,
          allowEnterKey: true,
        }).then(() => {
          router.push({name: "home"});
        });

      }).catch((error) => {
        if (error.response.status === 422) {
          Swal.fire('Αποτυχία!', 'Λανθασμένα στοιχεία εισόδου', 'error')
        }
        if (error.response.status === 500) {
          Swal.fire('Σφάλμα!', 'Υπήρξε ένα πρόβλημα στον server. Παρακαλούμε δοκιμάστε εκ νέου μετά από λίγα λεπτά, καθώς οι προγραμματιστές μας αυτή τη στιγμή προσπαθούν να διορθώσουν το πρόβλημα. Ζητούμε συγγνώμη για την ταλαιπωρία.', 'error')
        }
      });
    }
  },
}
</script>

<style scoped>
</style>