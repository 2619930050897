<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col-6"><i class="fas fa-tower-broadcast me-1"></i>Ραδιόφωνα Πελατών</div>
            <div class="col-6"><button type="button" class="btn btn-outline-dark float-end" @click="prepareStore"><i class="fas fa-plus me-1"></i>Προσθήκη</button></div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-xxl-3 col-xl-4 col-md-4">
              <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
                <option value="10">10 Εγγραφές / Σελίδα</option>
                <option value="25">25 Εγγραφές / Σελίδα</option>
                <option value="50">50 Εγγραφές / Σελίδα</option>
                <option value="100">100 Εγγραφές / Σελίδα</option>
              </select>
            </div>
            <div class="col-xxl-7 col-xl-4 col-md-4"></div>
            <div class="col-xxl-2 col-xl-4 col-md-4">
              <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchRadios">
            </div>
          </div>
          <table class="table table-hover">
            <thead>
            <tr>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="name" name="Όνομα" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="email" name="E-mail" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="client" name="Πελάτης" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="created_at" name="Ημ/νία Δημιουργίας" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="expires_at" name="Ημ/νία Λήξης" @sort="sort"/>
              <th class="text-center" scope="col">Ενέργειες</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center t-row" v-for="radio in radios" :key="radio.id">
              <td @click="selectRadio(radio)">{{ radio.name }}</td>
              <td @click="selectRadio(radio)">{{ radio.email }}</td>
              <td @click="selectRadio(radio)">{{ radio.client }}</td>
              <td @click="selectRadio(radio)">{{ radio.created_at }}</td>
              <td @click="selectRadio(radio)">{{ radio.expires_at }}</td>
              <td>
                <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs me-1"></i></button>
                  <ul class="dropdown-menu">
                    <li><button type="button" class="dropdown-item" @click="edit(radio)"><i class="fas fa-edit me-1"></i>Τροποποίηση</button></li>
                    <li><button type="button" class="dropdown-item" @click="destroy(radio)"><i class="fas fa-trash me-1"></i>Κατάργηση</button></li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <TableFooterComponent :search-value="table.search" :paging-object="paging" :data-length="radios.length" @page-changed="changePage"/>
        </div>
      </div>

      <RadiosModalComponent ref="radiosModal" @data-modified="fetchRadios"/>
    </div>
  </div>
</template>

<script>
import TableFooterComponent from "@/components/TableComponents/TableFooterComponent.vue";
import RadiosModalComponent from "@/views/Admin/Radios/AdminRadiosModalComponent.vue";
import TableColumn from "@/components/TableComponents/TableColumnComponent.vue";
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import errorHandle from "@/library/RequestErrorHandling";

export default {
  name: "RadiosTableComponent" ,
  components: {TableColumn, RadiosModalComponent, TableFooterComponent},
  data() {
    return {
      radios: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'name',
        sort_dir: 'asc',
        perPage: 10,
        page: 1,
        nameSearch: '',
      }
    }
  },
  mounted() {
    this.fetchRadios();
  },
  methods: {
    fetchRadios() {
      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);

      axios.post(apiRoutes.admin.radios.fetch, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('adminToken'),
          Accept: 'application/json'
        }
      }).then(response => {
        this.radios = response.data.results;
        this.paging.totalRecords = response.data.totalRecords;
      }).catch(error => errorHandle(error));
    },
    prepareStore() {
      this.$refs.radiosModal.prepareStore();
    },
    edit(position) {
      this.$refs.radiosModal.edit(position);
    },
    destroy(client) {
      this.$refs.radiosModal.destroy(client.id);
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchRadios();
    },
    changePage(page) {
      this.table.page = page;
      this.fetchRadios();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchRadios();
    },
    selectRadio(radio) {
      this.$emit('radio-selected', radio);
    }
  }
}
</script>

<style scoped>
.t-row {
  cursor: pointer;
}
</style>