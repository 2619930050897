<template>
  <div class="card mt-3">
    <div class="card-header"><i class="fas fa-image me-2"></i>Εικόνες
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home Page</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="false">E-mail Page</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="news-tab" data-bs-toggle="tab" data-bs-target="#news" type="button" role="tab" aria-controls="news" aria-selected="false">News Page</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <table class="table">
            <thead>
            <tr>
              <th class="text-center" scope="col"><i class="fas fa-up-down-left-right"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-image"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-gears"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center">LOGO Σταθμού</td>
              <td class="text-center tooltip-container">
                <img v-if="bannersImages.home_logo" :src="bannersImages.home_logo" alt="HOME LOGO" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="homeLogoBannerInput" type="file" class="form-control" @change="handleImageUpload('home', 'logo', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.homeLogoBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.home_logo" type="button" class="btn btn-outline-dark" @click="removeImageBanner('home', 'logo')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">ΠΑΝΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.home_top" :src="bannersImages.home_top" alt="HOME TOP" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="homeTopBannerInput" type="file" class="form-control" @change="handleImageUpload('home', 'top', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.homeTopBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.home_top" type="button" class="btn btn-outline-dark" @click="removeImageBanner('home', 'top')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">ΚΑΤΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.home_bottom" :src="bannersImages.home_bottom" alt="HOME BOTTOM" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="homeBottomBannerInput" type="file" class="form-control" @change="handleImageUpload('home', 'bottom', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.homeBottomBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.home_bottom" type="button" class="btn btn-outline-dark" @click="removeImageBanner('home', 'bottom')"><i class="fas fa-times"></i></button>
                   </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
          <table class="table">
            <thead>
            <tr>
              <th class="text-center" scope="col"><i class="fas fa-up-down-left-right"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-image"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-gears"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center">ΠΑΝΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.email_top" :src="bannersImages.email_top" alt="EMAIL TOP" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="emailTopBannerInput" type="file" class="form-control" @change="handleImageUpload('email', 'top', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.emailTopBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.email_top" type="button" class="btn btn-outline-dark" @click="removeImageBanner('email', 'top')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">ΚΑΤΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.email_bottom" :src="bannersImages.email_bottom" alt="EMAIL BOTTOM" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="emailBottomBannerInput" type="file" class="form-control" @change="handleImageUpload('email', 'bottom', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.emailBottomBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.email_bottom" type="button" class="btn btn-outline-dark" @click="removeImageBanner('email', 'bottom')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="news" role="tabpanel" aria-labelledby="news-tab">
          <table class="table">
            <thead>
            <tr>
              <th class="text-center" scope="col"><i class="fas fa-up-down-left-right"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-image"></i></th>
              <th class="text-center" scope="col"><i class="fas fa-gears"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center">ΠΑΝΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.news_top" :src="bannersImages.news_top" alt="NEWS TOP" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="newsTopBannerInput" type="file" class="form-control" @change="handleImageUpload('news', 'top', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.newsTopBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.news_top" type="button" class="btn btn-outline-dark" @click="removeImageBanner('news', 'top')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-center">ΚΑΤΩ</td>
              <td class="text-center">
                <img v-if="bannersImages.news_bottom" :src="bannersImages.news_bottom" alt="NEWS BOTTOM" class="img-fluid">
                <span v-else>-</span>
              </td>
              <td class="text-center w-50">
                <div class="row">
                  <div class="col d-none"><input ref="newsBottomBannerInput" type="file" class="form-control" @change="handleImageUpload('news', 'bottom', $event)"></div>
                  <div class="col">
                    <button type="button" class="btn btn-outline-dark me-2" @click="this.$refs.newsBottomBannerInput.click()"><i class="fas fa-file-arrow-up"></i></button>
                    <button v-if="bannersImages.news_bottom" type="button" class="btn btn-outline-dark" @click="removeImageBanner('news', 'bottom')"><i class="fas fa-times"></i></button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import apiRoutes from "@/router/apiRoutes";
import errorHandle from "@/library/RequestErrorHandling";

export default {
  name: "BannersComponent",
  props: ['radio'],
  data() {
    return {
      bannersImages: {
        home_logo: null,
        home_top: null,
        home_bottom: null,
        email_top: null,
        email_bottom: null,
        news_top: null,
        news_bottom: null,
      },
      banners: {
        home: {
          logo: {
            ref: null,
            value: null
          },
          top: {
            ref: null,
            value: null
          },
          bottom: {
            ref: null,
            value: null
          },
        },
        email: {
          top: {
            ref: null,
            value: null
          },
          bottom: {
            ref: null,
            value: null
          },
        },
        news: {
          top: {
            ref: null,
            value: null
          },
          bottom: {
            ref: null,
            value: null
          },
        }
      }
    }
  },
  created() {
    this.getImageBanners();
  },
  methods: {
    handleImageUpload(page, position, event) {
      if (!this.validateImageFile(event.target)) {
        Swal.fire('Σφάλμα!', 'Παρακαλώ επιλέξτε ένα έγκυρο αρχείο εικόνας!', 'error')
        return;
      }
      this.banners[page][position].value = event.target.files[0];
      this.updateImageBanner(page, position);
    },
    validateImageFile(image) {
      if (!image.files || !image.files[0]) {
        return false;
      }

      const file = image.files[0]; // Get the selected file

      // Check MIME type of the file
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      return validImageTypes.includes(file.type);
    },
    getImageBanners() {
      axios.post(apiRoutes.radios.getBanners, {
        "radio_id": this.radio.id
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: "application/json",
        }
      }).then(response => {
        Object.keys(response.data).forEach((key) => {
          this.bannersImages[key] = response.data[key][0];
        })
      }).catch((error) => errorHandle(error));
    },
    updateImageBanner(page, position) {

      const file = this.banners[page][position].value;

      const data = new FormData();
      data.append('radio_id', this.radio.id);

      data.append('page', page);
      data.append('position', position);
      data.append('file', file);

      axios.post(apiRoutes.radios.updateBanner, data, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Accept': 'application/json', "Content-Type": "multipart/form-data"}
      }).then((response) => {
        this.getImageBanners();
        Swal.fire('Επιτυχία!', response.data.message, 'success')
      });
    },
    removeImageBanner(page, position) {
      Swal.fire({
        title: "Προσοχή",
        html: "Θέλετε σίγουρα να αφαιρέσετε την εικόνα;",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Αφαίρεση",
        cancelButtonText: "Ακύρωση",
      }).then((r) => {
        if (r.isConfirmed) {
          const data = new FormData();

          data.append('radio_id', this.radio.id);
          data.append('page', page);
          data.append('position', position);

          axios.post(apiRoutes.radios.removeBanner, data, {
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Accept': 'application/json', "Content-Type": "multipart/form-data"}
          }).then((response) => {
            this.getImageBanners();
            Swal.fire('Επιτυχία!', response.data.message, 'success')
          });
        }
      });

    },
  }
}
</script>

<style scoped>
.img-fluid {
  max-height: 25px;
}
</style>