<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col-6"><i class="fas fa-users me-1"></i>Πελάτες</div>
            <div class="col-6"><button type="button" class="btn btn-outline-dark float-end" @click="prepareStore"><i class="fas fa-plus me-1"></i>Προσθήκη</button></div>
          </div>
          </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-xxl-3 col-xl-4 col-md-4">
              <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
                <option value="10">10 Εγγραφές / Σελίδα</option>
                <option value="25">25 Εγγραφές / Σελίδα</option>
                <option value="50">50 Εγγραφές / Σελίδα</option>
                <option value="100">100 Εγγραφές / Σελίδα</option>
              </select>
            </div>
            <div class="col-xxl-7 col-xl-4 col-md-4"></div>
            <div class="col-xxl-2 col-xl-4 col-md-4">
              <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchClients">
            </div>
          </div>
          <table class="table table-hover">
            <thead>
            <tr>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="name" name="Ονοματεπώνυμο" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="email" name="E-mail" @sort="sort"/>
              <TableColumn :sort-dir="table.sort_dir" :sort-field="table.sort_by" field="radios" name="Ραδιόφωνα" @sort="sort"/>
              <th class="text-center" scope="col">Ενέργειες</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center t-row" v-for="client in clients" :key="client.id">
              <td>{{ client.name }} {{ client.surname }}</td>
              <td>{{ client.email }}</td>
              <td>{{ client.radios }}</td>
              <td>
                <div class="dropdown">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs me-1"></i></button>
                  <ul class="dropdown-menu">
                    <li><button type="button" class="dropdown-item" @click="edit(client)"><i class="fas fa-edit me-1"></i>Τροποποίηση</button></li>
                    <li><button type="button" class="dropdown-item" @click="destroy(client)"><i class="fas fa-trash me-1"></i>Κατάργηση</button></li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <TableFooterComponent :search-value="table.search" :paging-object="paging" :data-length="clients.length" @page-changed="changePage"/>
        </div>
      </div>

      <ClientsModalComponent ref="clientsModal" @data-modified="fetchClients"/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TableColumn from "@/components/TableComponents/TableColumnComponent.vue";
import TableFooterComponent from "@/components/TableComponents/TableFooterComponent.vue";
import apiRoutes from "@/router/apiRoutes";
import ClientsModalComponent from "@/views/Admin/Clients/ClientsModalComponent.vue";

export default {
  name: "ClientsTableComponent",
  components: {ClientsModalComponent, TableColumn, TableFooterComponent},
  data() {
    return {
      clients: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'name',
        sort_dir: 'asc',
        perPage: 10,
        page: 1,
        nameSearch: '',
      }
    }
  },
  mounted() {
    this.fetchClients();
  },
  methods: {
    fetchClients() {
      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);

      axios.post(apiRoutes.admin.clients.fetch, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('adminToken'),
          Accept: 'application/json'
        }
      }).then(response => {
        this.clients = response.data.results;
        this.paging.totalRecords = response.data.totalRecords;
      });
    },
    prepareStore() {
      this.$refs.clientsModal.prepareStore();
    },
    edit(position) {
      this.$refs.clientsModal.edit(position);
    },
    destroy(client) {
      this.$refs.clientsModal.destroy(client.id);
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchClients();
    },
    changePage(page) {
      this.table.page = page;
      this.fetchClients();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchClients();
    }
  }
}
</script>

<style scoped>
.t-row {
  cursor: pointer;
}
</style>