<template>
  <div id="layoutError">
    <div id="layoutError_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="text-center mt-4">
                <h1 class="display-1">404</h1>
                <p class="lead">Η σελίδα που ζητήσατε δεν βρέθηκε</p>
                <router-link :to="{name: 'home'}">
                  <i class="fas fa-arrow-left me-1"></i>
                  Επιστροφή στην αρχική
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutError_footer">
      <FooterComponent/>
    </div>
  </div>
</template>

<script>

import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "NotFoundComponent",
  components: {FooterComponent}
}
</script>

<style scoped>

</style>