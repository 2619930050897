<template>
  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
      <div class="nav">
        <div class="sb-sidenav-menu-heading">ΠΡΟΕΠΙΣΚΟΠΙΣΗ</div>
        <router-link :to="{name: 'adminDashboard'}" :class="'nav-link' + (this.activePage === 'adminDashboard' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-house"></i></div>
          Αρχική
        </router-link>
        <div class="sb-sidenav-menu-heading">ΡΥΘΜΙΣΕΙΣ</div>
        <router-link :to="{name: 'adminClients'}" :class="'nav-link' + (this.activePage === 'adminClients' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-users"></i></div>
          Πελάτες
        </router-link>
        <router-link :to="{name: 'adminRadios'}" :class="'nav-link' + (this.activePage === 'adminRadios' ? ' active': '')">
          <div class="sb-nav-link-icon"><i class="fas fa-tower-broadcast"></i></div>
          Ραδιόφωνα Πελατών
        </router-link>
      </div>
    </div>
    <div class="sb-sidenav-footer text-center">
      <div class="small mb-2">Created & Hosted by:</div>
      <a href="https://gr-net.gr" target="_blank"><img src="../assets/img/gr-net-white.webp" class="img-fluid" alt="GR-NET"></a>
    </div>
  </nav>
</template>

<script>

export default {
  name: "AdminSidebarComponent",
  props: ['activePage']
}
</script>

<style scoped>

</style>